  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/4-2-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/legal/'>Qui sommes nous ? </a></li><li>Mentions légales</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Mentions légales</h1>

<p>Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et visiteurs, ci-après l'"Utilisateur", du site www.rvexpertises.fr , ci-après le "Site", les présentes mentions légales.</p>



<p>La connexion et la navigation sur le Site par l’Utilisateur implique l'acceptation intégrale et sans réserve des présentes mentions légales.</p>

<p>Ces dernières sont accessibles sur le Site à la rubrique « Mentions légales ».</p>
<h2>ARTICLE 1 - L'ÉDITEUR</h2>
<p>L’édition et la direction de la publication du Site est assurée par le cabinet RV Expertises, domiciliée au 6 Av. de l'Armée d'afrique, 13100 Aix-en-Provence, dont le numéro de téléphone est le 06 48 48 26 57 et l'adresse e-mail contact[arobase]rvexpertises.fr</p>
<p> </p>
<p>ci-après l'"Editeur".</p>
<h2>ARTICLE 2 - L'HÉBERGEUR</h2>
<p>L’hébergeur du site www.rvexpertises.fr est la société Netlify, dont le siège social est situé au 610 22ND St Ste 315 San Francisco, CA, 94107-3163 United States, avec le numéro de téléphone : (925) 922-0921.</p>

<h2>ARTICLE 3 - ACCÈS AU SITE</h2>
<p>Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant découlant d’une nécessité de maintenance.</p>

<p>En cas de modification, interruption ou suspension du Site, l'Editeur ne saurait être tenu responsable.</p>
<h2>ARTICLE 4 - COLLECTE DES DONNÉES</h2>
<p>Le Site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés. </p>

<p>En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit :</p>
<p>·         par mail à l'adresse email contact[arobase]rvexpertises.fr</p>
<p> </p>
<p>Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du Site, sans autorisation de l’Editeur est prohibée et pourra entraîner des actions et poursuites judiciaires telles que notamment prévues par le Code de la propriété intellectuelle et le Code civil.</p>

<p>Pour plus d’informations, se reporter aux CGU du site www.rvexpertises.fr accessible à la rubrique "CGU" </p>











                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/legal/mentions-legales/' className='active'>Mentions légales</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details